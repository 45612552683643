// Gallery.jsx
import React from 'react';
import {Container} from 'react-bootstrap';
import {StaticImage} from "gatsby-plugin-image";
import SimpleReactLightbox, {SRLWrapper} from "simple-react-lightbox";

const GalleryAustralia2014 = () => {
    return (
        <Container>
            <a href="/Gallery">Return to Gallery</a>
            <h1>Australia 2014</h1>
            <h2>Perth Gasshuku</h2>
            <SimpleReactLightbox><SRLWrapper>
                <StaticImage src="../images/gallery/Australia2014/1083968_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/1133986.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/1136409_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/1183910_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/1184878_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/1193972_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/1207819_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/1214262_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/1223794_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/126695_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/1273492_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/1289047_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/1336474_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/1408088_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/1439400_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/1714832_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/1782558_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/1813465_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/1827738.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/1835649.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/224366_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/252486_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/269311_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/328770_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/351916_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/373888_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/381228_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/393704_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/434326_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/454294_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/474891_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/485978_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/508166_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/56704_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/639048_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/656669_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/685560_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/687727_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/764711_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/85700_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/867877_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/872453_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/892427_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/9054609.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/9080869_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/9108072_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/9120377_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/9137903_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/9149945_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/917236_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/9289691.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/9291552_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/9347578_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/9355394_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/9368665_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/9396052_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/9415090_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/9511516_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/9540431_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/9584090_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/9653346_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2014/9829034_orig.jpg" alt=""/>
            </SRLWrapper></SimpleReactLightbox>
        </Container>
    );
}

export default GalleryAustralia2014;
